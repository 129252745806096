// import { Application } from "@hotwired/stimulus";
// const application = Application.start();
import Swiper from "swiper";

import 'animate.css';
//import '../webpack/css/main_embedable.scss';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap-grid.min.css';
//import IframeResizer from './objects/iframe_resizer';


import 'popper.js';

if(typeof window.itbWaitForLibrary === "undefined") window.itbWaitForLibrary =  (name)=>{
  let limit = 10;
  return new Promise((resolve,reject)=>{
    if(typeof window.itb === 'object'){
      window.itb.resolveScript(name,resolve, reject);
    }
    let stop = setInterval(()=>{
      if(typeof window.itb === 'object'){
        clearInterval(stop);
        window.itb.resolveScript(name,resolve, reject);
      }
      if(limit<=0){
        clearInterval(stop);
        reject('returns with error');
      }
      limit--;
    },1000)
  });
}


window.itbWaitForLibrary().then(()=>{
  let application = window.itb.application;
  let context;

  // context = require.context("./controllers", true, /\.js$/);
  // context.keys().forEach((key) => {
  //   const controllerName = key.replace("./", "").replace(".js", "").replace("_controller", "").replace(/_/g, "-");
  //   const controllerModule = context(key);
  //   application.register(controllerName, controllerModule.default);
  // });

  context = require.context("./controllers-embedable", true, /\.js$/);
  context.keys().forEach((key) => {
    const controllerName = key.replace("./", "").replace(".js", "").replace("_controller", "").replace(/_/g, "-");
    const controllerModule = context(key);
    application.register(controllerName, controllerModule.default);
  });

  let $ = itb.$;

});
//Css



//You should use it at very begining of a script.
// So we can start listening for incoming messages ASAP
/*const resizer = new IframeResizer();

resizer.init();
if (window.itbWaitForLibrary){
  window.itbWaitForLibrary().then(()=>itb.resizer = resizer);
}*/











